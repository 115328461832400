// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bio-en-jsx": () => import("./../../../src/pages/bio.en.jsx" /* webpackChunkName: "component---src-pages-bio-en-jsx" */),
  "component---src-pages-biografia-jsx": () => import("./../../../src/pages/biografia.jsx" /* webpackChunkName: "component---src-pages-biografia-jsx" */),
  "component---src-pages-contact-us-en-jsx": () => import("./../../../src/pages/contact-us.en.jsx" /* webpackChunkName: "component---src-pages-contact-us-en-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pieces-en-jsx": () => import("./../../../src/pages/pieces.en.jsx" /* webpackChunkName: "component---src-pages-pieces-en-jsx" */),
  "component---src-pages-piezas-jsx": () => import("./../../../src/pages/piezas.jsx" /* webpackChunkName: "component---src-pages-piezas-jsx" */),
  "component---src-pages-store-en-jsx": () => import("./../../../src/pages/store.en.jsx" /* webpackChunkName: "component---src-pages-store-en-jsx" */),
  "component---src-pages-tienda-jsx": () => import("./../../../src/pages/tienda.jsx" /* webpackChunkName: "component---src-pages-tienda-jsx" */),
  "component---src-templates-news-en-jsx": () => import("./../../../src/templates/news.en.jsx" /* webpackChunkName: "component---src-templates-news-en-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-pieces-en-jsx": () => import("./../../../src/templates/pieces.en.jsx" /* webpackChunkName: "component---src-templates-pieces-en-jsx" */),
  "component---src-templates-pieces-jsx": () => import("./../../../src/templates/pieces.jsx" /* webpackChunkName: "component---src-templates-pieces-jsx" */),
  "component---src-templates-product-en-jsx": () => import("./../../../src/templates/product.en.jsx" /* webpackChunkName: "component---src-templates-product-en-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}

